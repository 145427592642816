<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import createForm from "./components/createForm.vue";
    import moment from "moment";
    
    export default {
        page: {
            title: "Edit Clone Shifts",
            meta: [
            {
                name: "description",
                content: appConfig.description,
            },
            ],
        },
      
        components: {
            Layout,
            PageHeader,
            createForm
        },
        data() {
            return {
                processing:false,
                dyn_areas:[],
                location_id:null,
                  shifts:[],
               
                title: "Edit Clone Shifts",
                items: [
                    {
                        text: "Clone Shifts",
                    },
                    {
                        text: "Edit",
                        active: true,
                    },
                ],
                
            };
        },
         mounted(){
        this.fetchShift();
        },

     
        methods: {
            fetchShift() {
                this.shifts=this.$store.state.shift.shift;
                if(this.shifts.length > 0)
                {
                    this.shifts.map((shift)=>{
                        shift.areas=[];
                    this.makeAreas(shift);
                    shift.areas= Object.values(shift.areas.reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}));
                    this.addRoleInAreas(shift);
                    shift.id=null;

                    // shift.utc_from=this.changeDateTimeToUTCZero(shift.from);
                    // shift.utc_to=this.changeDateTimeToUTCZero(shift.to);
                    })
                }else{
                    this.$router.push("/shifts");
                }
                
            },
            makeAreas(shift){
                shift.area_roles.map((role)=>{
                    shift.areas.push(role.area);
                })
            },
       
            addRoleInAreas(shift){
                shift.areas.map((area)=>{
                    area.area_role=[];
                shift.area_roles.map((role)=>{
                        if(area.id==role.area_id){
                             area.area_role.push({area_id:role.area_id,id:role.id,name:role.name,count:role.pivot.role_count,role_count:role.pivot.role_count,remaining_slots:role.pivot.role_count});
                        }
                    })
                })
            },
            changeFormat(shift){
                    // shift.from=this.changeDateTimeToLocalFormats(shift.from);
                    // shift.to=this.changeDateTimeToLocalFormats(shift.to);
                    
                    let from = shift.from.split(".");
                    let to = shift.to.split(".");
                    shift.from= from[0];
                    shift.to= to[0];
                    return shift;
            },

    uploadShift() {

                  let self = this;
            if (self.processing) {
                return;
            }
            self.processing = true;
            
            self.$axios.post('/shift/store-cloned-shifts', {
                shifts:this.shifts
                
          })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                    
                            self.$router.push('/shifts');
                    
                                     
                })
                .catch(error => {
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });
    },

        },

        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title" />
        <template v-if="shifts.length > 0">
            <div class="card p-4 radius-20" v-for="(shift ,index) in shifts" :key="index">
                <h4 class="mb-3">{{shift.interval}}</h4>
            <create-form  :areas="shift.areas" :location_id="location_id" :shift="changeFormat(shift)" :dyn_areas="dyn_areas" ></create-form>
            </div>
            <div class="col-md-12 mb-4">
                <SubmitButton text="Update Shift" @clicked="uploadShift" :processing="processing"></SubmitButton>
            </div>
        </template>
    </Layout>
</template>
